// custom site specific css theme goes here - overriding bootstrap less files locally is also ok
// (copy from base, and update local bootstrap.less)

@import "components/megamenu.less";

/*** start:: global ***/
html,
body {
	overflow-x:auto\9; /*hack for ie9*/
  overflow-x:hidden;
}

body {
	color: #333;
	font-family:Arial, Helvetica, sans-serif;
}

body.modal-open {
	padding-right: 0px !important; /*hack to stop page shifting left when modal is open*/
}

* {
	outline: none !important;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 15px;
	margin-top: 0px;
}

h1 {
	font-size: 40px;
}

h2 {
	font-size: 28px;
}

h3{
	font-size: 20px;
}

h4 {
	font-size: 16px;
	font-weight: bold;
}

h5 {
	font-size: 14px;
}

a,
a:hover,
a:focus {
	color: #e42313;
}

@media screen and (min-width: 767px) {
	a[href^="tel:"] {
		color: #333;
		cursor: default;
		text-decoration: none;
	}
}

ul ul {
	list-style-type: disc;
	margin-bottom: 10px;
}

.bullet-list ul {
 // list-style: none;
  padding: 0;
	margin-left: 15px;
}

//.bullet-list li:before {
 // content: " " !important;
 // color: #e42313;
 // margin-right: 7px;
//	margin-left: -15px;
//}

@media screen and (max-width: 991px) {
	.full-size-img table {
  display: block;
  overflow-x: auto;
	}

	.full-size-img img {
	max-width:none;
	}
}

.sub-heading {
  margin-bottom: 0px;
}

.grey {
	color: #808284;
}

.red {
	color: #e42313
}

.button-md {
	background-color: #e42313;
	color: #fff;
  display: inline-block;
	font-size: 20px;
	height: 43px!important;
	margin-bottom: 20px;
  margin-top: 20px;
	min-width: 155px;
	padding-left: 21px;
	padding-right: 21px;
	padding-top: 8px;
  text-align: center;
}

.button-md:hover {
	background-color: #D52618;
}

.border-button-md {
  border: 3px solid #fff;
  color: #fff;
  display: inline-block;
  height: 43px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 4px;
}

.border-button-md:hover,
.border-button-md:focus {
  background-color: #e42313;
  border: 3px solid #e42313;
  color: #fff;
}

.border-button-md-white {
  display: inline-block;
  height: 43px;
  padding-top: 4px;
  padding-right: 21px;
  padding-left: 21px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
  border: 3px solid #fff;
}

.border-button-md-white:hover,
.border-button-md-white:focus {
  background-color: #fff;
  border: 3px solid #fff;
  color: #e42313;
}

.page-content {
  padding: 40px 0px;
}

.page-content.referenced-bottom {
	padding-top: 0px;
}

.page-content.referenced-top {
	padding-bottom: 0px;
}

.row-highlight {
	background-color: #f2f3f3;
}

.tab-content {
  padding: 0px 35px;
}

@media screen and (max-width: 767px) {
	.tab-content {
		padding: 0px 15px;
	}
}

.team-photos table {
	margin-top: 20px;
}

.team-photos img {
	margin-bottom: 10px;
	max-width: none;
	padding-right: 15px;
	padding-left: 15px;
}

.team-photos td {
  padding-bottom: 15px;
}

.team-photos table td a {
	word-break: break-all;
}

@media screen and (max-width: 767px) {
	.team-photos img {
		padding-left: 0px;
	}

   .team-photos tr td:nth-child(odd) {
    padding-bottom: 0px !important;
   }
}

.documents-img img {
  max-width: none !important;
}

/* start:: login modal */
.login .btn-default:hover {
	background-color: #D52618;
}

.modal-content {
	border-radius: 0;
}

.modal-header {
	border-bottom: none;
}

.modal-header button.close {
	display: none;
}

.primary-nav-login .modal-body .button-md {
	min-width: 252px;
	margin: 0px;
}

.modal-footer {
	border-top: none;
}

.modal-footer .btn-default {
	border: none;
	color: #e42313;
}

.modal-footer .btn-default:hover,
.modal-footer .btn-default:focus {
	background: none;
	color: #e42313;
}
/* end:: login modal */

/* start:: page header */
.page-header {
	background-size: cover !important;
	background-repeat: no-repeat !important;
  margin: 0px;
	padding-bottom: 0px;
}

.page-header .breadcrumb {
  background: none;
  border-radius: 0px;
	font-size: 11px;
  list-style: none;
	margin: 15px 0 0 0;
	padding: 0;
}

.breadcrumb>li+li:before {
  color: #fff;
  content: ">";
  padding: 0 5px;
}

.page-header .breadcrumb li a,
.page-header .breadcrumb li.active {
  color: #fff;
}

@media screen and (max-width: 767px) {
	.page-header .breadcrumb {
		display: none;
	}
}

.page-header h1 {
  color: #fff;
	font-size: 35px;
  margin-bottom: 50px
}

@media screen and (max-width: 767px) {
	.page-header h1 {
		font-size: 25px;
		margin-top: 10px;
		margin-bottom: 35px
	}
}

.http-error-header h1 {
  margin-top: 20px;
}

.http-error-header {
  min-height: 100px;
}
/* end:: page header */

/* start:: table */
.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
  border-bottom: 1px solid #ddd;
  border-top: none;
  padding: 10px;
}

.table>thead>tr>th.no-border,
.table>tbody>tr>th.no-border,
.table>tfoot>tr>th.no-border,
.table>thead>tr>td.no-border,
.table>tbody>tr>td.no-border,
.table>tfoot>tr>td.no-border {
  border-bottom: 0px;
}

.table>thead>tr>th.portfolio-manager,
.table>tbody>tr>th.portfolio-manager,
.table>tfoot>tr>th.portfolio-manager,
.table>thead>tr>td.portfolio-manager,
.table>tbody>tr>td.portfolio-manager,
.table>tfoot>tr>td.portfolio-manager {
  display: inline-block;
	width: auto;
}

.table-documents>thead>tr>td,
.table-documents>tbody>tr>td,
.table-documents>tfoot>tr>td {
  border-bottom: none;
}

.table-documents img {
  max-width: none;
}

td[valign="middle"] {
  vertical-align: middle !important;
}

th.cell-highlight,
td.cell-highlight {
	background: #f2f3f3;
	font-weight: bold;
}

@media screen and (max-width: 767px) {
	.fund-details table td {
		display: block;
		width: 100%;
	}
}
/* end:: table */

/* start:: page tabs */
.nav-tabs {
  margin-bottom: 30px;
  margin-top: 0px;
}

.nav-tabs>li {
  margin-bottom: -2px;
}

.nav-tabs>li>a {
	color: #333;
	padding: 10px 25px;
}

.nav-tabs>li>a:hover,
.nav-tabs>li>a:focus {
	background: none;
	border-color: transparent;
	outline: none;
}

.nav-tabs>li.active {
	border-bottom: 2px solid #e42313;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
	border-color: transparent;
	color: #e42313;
}
/* end:: page tabs */

/* start:: accordion */
.panel-group {
	margin-top: 20px;
}

.panel-group .panel {
	box-shadow: none;
  margin-bottom: 15px;
}

.panel {
	border: none;
}

.panel-default>.panel-heading {
	background-color: #e6e7e8;
	padding: 12.5px 15px;
}

h3.panel-title {
	font-size: 14px;
}

h4.panel-title {
	font-size: 14px;
}

.panel-title>a {
	display: block;
}

.panel-title>a:hover,
.panel-title>a:focus {
	color: #333;
	outline: none;
	text-decoration: none;
}

.panel-title>a {
	background-image: url("/content/dam/antares/images/icons/icon_accordion_minus_drk.png");
	background-position: 100% 50%;
	background-repeat: no-repeat;
}

.panel-title>a.collapsed {
  background-image: url("/content/dam/antares/images/icons/icon_accordion_plus.png");
}

.list-item-text {
  margin: 25px 15px;
}

.archive-accordion.panel-default>.panel-heading {
  background: none;
	border-bottom: 1px solid #e6e7e8;
  padding: 12.5px 0px;
}

.archive-accordion .panel-title>a {
  background-image: url("/content/dam/antares/images/icons/accor-sm-minus-rd.png");
  background-position: 0% 40%;
  color: #e42313;
  font-weight: bold;
  padding-left: 18px;
}

.archive-accordion .panel-title>a.collapsed {
	background-image: url("/content/dam/antares/images/icons/accor-sm-plus-rd.png");
}

.archive-accordion .list-item-text {
  margin: 15px 0px;
}

.disclaimers-accordion.panel-default>.panel-heading {
	background: none;
	padding: 12.5px 0px;
}

.disclaimers-accordion .panel-title>a {
	background-image: url("/content/dam/antares/images/icons/accor-sm-gray-minus.png");
  background-position: 0% 50%;
	color: #58585b;
	padding-left: 33px;
	text-decoration: underline;
}

.disclaimers-accordion .panel-title>a.collapsed {
	background-image: url("/content/dam/antares/images/icons/accor-sm-gray-plus.png");
}

.disclaimers-accordion .list-item-text {
  margin: 15px 0px;
}
/* end:: accordion */

/* start:: get in touch */
.get-in-touch {
  background-color: #e6e7e8;
  padding: 40px 0px;
}

.get-in-touch  h2 {
	margin-bottom: 40px;
}

.get-in-touch h4 {
  margin-bottom: 10px;
}

.get-in-touch p {
  font-size: 12px;
}
/*end:: get in touch */

/* start:: global footer */
.nav-footer-wrapper {
	background-color: #58585b;
	color: #fff;
	padding: 40px 0px;
}

.nav-footer-wrapper .nav-tabs {
	border-bottom: none;
	margin-bottom: 0px;
	margin-top: 0px;
}

.nav-footer-wrapper .nav-tabs>li {
	margin-bottom: 0px;
}

.nav-footer-wrapper .nav-tabs>li.active {
	border-bottom: transparent
}

.nav-footer-wrapper .nav-tabs>li.active>a {
	background: none;
}

.nav-footer-wrapper .nav-tabs>li.active>a:hover,
.nav-footer-wrapper .nav-tabs>li.active>a:focus {
	border: none;
	color: #fff;
}

.nav-footer-wrapper .nav-tabs>li>a {
  border: none;
	color: #fff;
	font-size: 13px;
	margin-bottom: 15px;
}

.nav-footer-wrapper .nav>li>a {
	padding: 0px 10px 0px 0px;
}

.nav-footer-wrapper .nav>li>a:hover,
.nav-footer-wrapper .nav>li>a:focus {
	background: none;
  border-color: none;
}

.nav-footer-wrapper .footer-copyright p {
  font-size: 13px;
}

.nav-footer-wrapper p {
  font-size: 11px;

}
/* end:: global footer */
/*** end:: global ***/

/*** start:: home ***/
.carousel-content-list-wrapper .item {
	height:400px;
	background-position: top center !important;
	background-size: cover !important;
	background-repeat: no-repeat;
}

.carousel-content-list-wrapper .container {
  width: 100%;
}

.carousel-full-width {
	text-align:center;
}

.carousel-full-width img {
	height:400px;
}

.carousel-full-width .carousel-caption {
	margin: 0 auto;
	/*position: relative;*/
	text-align: left;
	top: 20%;
	width:900px;
}

.carousel-full-width .carousel-caption {
	right: 0% !important;
	left: 0% !important;
}

.carousel-caption .list-item-text {
	margin:0;
}

.carousel-full-width .carousel-caption p {
  font-size: 20px;
	text-shadow: none;
}

.carousel-content-list-wrapper [data-slide="prev"],
.carousel-content-list-wrapper [data-slide="next"] {
  display: none;
}

.carousel-indicators li {
	border:none;
	background-color:rgba(255,255,255,0.5)
}

@media screen and (max-width: 1199px) {
    .carousel-full-width .carousel-caption {
		/*left: 5%;*/
		/*right: 5%;*/
		top: 20%;
		width: 85%;
	}
}

@media screen and (max-width: 1099px) {
		.carousel-content-list-wrapper .container {
			padding-left:0;
			padding-right:0
		}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.carousel-content-list-wrapper .item {
		height:290px
	}
	.carousel-full-width .carousel-caption {
		top:10%;
	}

}

/*@media screen and (max-width: 991px) {
	.carousel-content-list-wrapper .item {
		height:340px;
	}

	.carousel-full-width img {
		height:340px;
	}
}*/

@media screen and (max-width: 767px) {
	.carousel-content-list-wrapper .item {
		height: 150px;
	}

	.carousel-full-width img {
	   height: 290px;
	}

  .carousel-full-width .carousel-caption {
		top: 0%;
		width: 90%;
	}

	.carousel-full-width .carousel-caption h1 {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.carousel-full-width .carousel-caption p {
		font-size: 11px
	}

	.carousel-full-width .carousel-caption .list-item-text {
		margin-left: 0px;
		margin-top: 0px;
	}

	.carousel-full-width .carousel-caption .border-button-md,
	.carousel-full-width .carousel-caption .border-button-md-white {
		border-width: 1px;
		height: inherit !important;
	  margin-top: 0px;
	  padding: 3px 7px !important;
	}

	.carousel-indicators  {
		margin-bottom: 0;
	}
	.carousel-indicators .active {
		width:8px;
		height:8px
	}

	.carousel-indicators li {
		width:6px;
		height:6px
	}
}

/* start: cta home page */
/*make background color in the image area */
.antares-investments .ctaWrapper .col-xs-5 {
  background-color:#762123 !important;
  background-image:url(/content/dam/antares/images/content/cta-equities.png);
  background-size:cover;
  background-position:center top;
}
.antares-investments .ctaWrapper2 .col-xs-5 {
background-color:#16497c !important;
background-image:url(/content/dam/antares/images/content/cta-fixed.png);
background-size:cover;
background-position:center;
}


/*style blurb text area */
.antares-investments .col-xs-7{                     .antares-unit-prices tr td:first-child {
	width: 32.5%;
}

.antares-unit-prices tr td:nth-child(2),
.antares-unit-prices tr td:nth-child(3),
.antares-unit-prices tr td:nth-child(4),
.antares-unit-prices tr td:nth-child(5) {
	width: 16.875%
}
background-color:#fff !important;
padding:10px 20px;
-webkit-box-shadow: 0 1px 1px rgba(0,0,0,.1);       .antares-unit-prices tr td:first-child {
	width: 32.5%;
}

.antares-unit-prices tr td:nth-child(2),
.antares-unit-prices tr td:nth-child(3),
.antares-unit-prices tr td:nth-child(4),
.antares-unit-prices tr td:nth-child(5) {
	width: 16.875%
}
box-shadow: 0 1px 1px rgba(0,0,0,.1);}

/*make blurb text position center vertical */
.antares-investments .media { display: table-cell; vertical-align: middle;}

/*make img center horizontal and vertical */
.antares-investments .col-xs-5 img { width: 90%; height: auto;
   position: absolute; top: 50%; left: 50%;
 margin-right: -50%; transform: translate(-50%, -50%)
 }


@media screen and (min-width:1100px){

/*adjust the cta position */
.antares-investments {margin-left:30px}

/*adjust the height between the image col and the blurb col */
.antares-investments .col-xs-5,
.antares-investments .col-xs-7
{height:150px;}

/*adjust the width between the image col and the blurb col */
.antares-investments .col-xs-5{ width:170px; }
.antares-investments .col-xs-7{ width:300px; }

/*adjust h3*/
.antares-investments  h3 {margin-bottom:8px;}

/*adjust height for blurb*/
.antares-investments .media {height:130px;   }

}

@media screen and (min-width:991px) and (max-width:1099px){

/*adjust the cta position */
.antares-investments {margin-left:25px}

/*adjust the height between the image col and the blurb col */
.antares-investments .col-xs-5,
.antares-investments .col-xs-7
{height:150px;}


/*adjust the width between the image col and the blurb col */
.antares-investments .col-xs-5{ width:170px; }
.antares-investments .col-xs-7{ width:260px; }

/*adjust h3*/
.antares-investments  h3 {margin-bottom:8px;}

/*adjust height for blurb*/
.antares-investments .media {height:130px  }

}

@media screen and (min-width:768px) and (max-width:992px){

/*adjust the cta position */
.antares-investments {margin-left:10px}

/*adjust the height between the image col and the blurb col */
.antares-investments .col-xs-5,
.antares-investments .col-xs-7
{height:120px;}

/*adjust the width between the image col and the blurb col */
.antares-investments .col-xs-5{ width:30%; }
.antares-investments .col-xs-7{ width:65%;}


/*adjust h3*/
.antares-investments  h3 {margin-top:-5px;margin-bottom:5px;}
.antares-investments  h3 {font-size:18px}
.antares-investments  p {font-size:13px}

/*adjust height for blurb*/
.antares-investments .media {height:120px;  }

}

@media screen and (max-width:767px){

/*adjust the cta position */
.container .antares-investments {padding-left:15px;padding-right:15px;}

/*adjust the height between the image col and the blurb col */
.antares-investments .col-xs-5,
.antares-investments .col-xs-7
{height:120px;}


/*adjust the height between the image col and the blurb col */
.antares-investments .ctaWrapper2 .col-xs-5,
.antares-investments .ctaWrapper2 .col-xs-7 {margin-top:20px;}


/*adjust the width between the image col and the blurb col */
.antares-investments .col-xs-5{ width:20%;  }
.antares-investments .col-xs-7{ width:80%;}

/*adjust h3*/
.antares-investments  h3 {margin-top:-5px; margin-bottom:5px;}
.antares-investments  h3 {font-size:18px}
.antares-investments  p {font-size:13px}

/*adjust height for blurb*/
.antares-investments .media {height:120px;  }

}

/*CTA medium to desktop screen */
@media screen and (min-width: 768px) {
.lt-ie9 .antares-investments .col-xs-5 img {top:10px;left:10px;}

}

/*CTA small screen*/
@media screen and (max-width: 767px) {

.lt-ie9 .antares-investments .col-xs-5 img {top:0px;left:5px;}

}
/* end: cta home page */

.antares-online img {
	max-width: none;
}

.antares-online .media-body {
	vertical-align: middle;
}

.antares-online .button-md {
	margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
		.antares-online .media-left {
			display: none;
		}

    .antares-online .media-body {
			display: table;
			width: 100%;
			text-align: center;
    }
    .antares-online img {
      max-width: 100%;
			margin-bottom: 20px;
    }

}

.latest-news-and-insights {
	padding: 40px 0px;
}

.latest-news-and-insights  h2 {
	margin-bottom: 40px;
}

.latest-news-and-insights .panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
	margin-bottom: 40px;
  padding: 20px;
}

.latest-news-and-insights .panel p:last-of-type {
  margin-bottom: 0px;
}

@media screen and (min-width: 768px) and (max-width: 991px){
	.latest-news-and-insights .panel {
		min-height: 248px;
	}
}

@media screen and (min-width: 992px) {
	.latest-news-and-insights .panel {
		min-height: 211px;
	}
}
/*** end:: home ***/

/*** start:: unit prices ***/
.antares-unit-prices {
	position: relative;
}

.antares-unit-prices .input-group {
	margin-bottom:30px;
}

.antares-unit-prices .form-control {
	padding:10px;
	height:40px;
}

.antares-unit-prices .dropdown-menu {
	left: auto !important;
	right: 0px;
}

.antares-unit-prices .form-control {
	border-radius: 0px;
}

.antares-unit-prices .btn,
.antares-unit-prices .btn-info,
.antares-unit-prices .btn-danger {
	border-radius:0
}

.antares-unit-prices .input-group-btn .btn {
	background-color: #e42313;
	border-color: #e42313;
	color: #fff;
	height: 40px;
}

.antares-unit-prices .input-group-btn .btn:hover {
	background-color: #D52618;
}

.antares-unit-prices .btn-info.active {
	background-color: #e42313;
	border-color: #e6e7e8;
	color: #fff;
}

.antares-unit-prices .btn-info {
	background-color: #1a4175;
	border-color: #1a4175;
}

.antares-unit-prices .btn-danger {
	background-color: #e42313;
	border-color: #e42313;
}

.antares-unit-prices .btn-success {
	background-color: #58585b;
	border-color:  #58585b;
}

.antares-unit-prices .dropdown-menu {
	padding: 20px 20px;
	margin: 0;
	border-radius: 0px;
}

.antares-unit-prices h4.panel-title {
   font-weight: normal;
}

.antares-unit-prices .collapsed .panel-title>a {
  background-image: url("/content/dam/antares/images/icons/icon_accordion_plus.png");
}

.antares-unit-prices table thead th,
.antares-unit-prices table thead td {
   font-weight: bold;
}

.antares-unit-prices tr td:first-child {
	width: 32.5%;
}

@media screen and (max-width: 767px) {
	 .antares-unit-prices .table-responsive {
		border:0;
	}
   .antares-unit-prices .dropdown-menu {
	 background-color: #e6e7e8;
		padding: 20px 5px;
		margin: 0;
		width: 265px;
	}
}
/*** end:: unit prices ***/

/*** start:: overview ***/
.overview-panel .media-left {
	display: block; /*hack for responsive images*/
}

.overview-panel img {
    margin-bottom: 7.5px;
}

.overview-panel a {
    color: #333;
}

.overview-panel h3 a:hover {
    color: #e42313;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
	.overview-panel:first-child	{
      border-top: 1px solid #ddd;
   }
   .overview-panel {
      border-bottom: 1px solid #ddd;
   }
	.overview-panel img {
	   display: none;
	}
	.overview-panel h3 {
		 font-size: 18px;
	   margin-top: 15px;
	}

	.overview-panel .text-image h3 a {
		position:relative;
		display:block;
		padding:5px;
		text-decoration:none;
	}

	.overview-panel .text-image h3 a:after {
		position:absolute;
		content:'';
		background-image:url(/content/dam/antares/images/icons/chevron-right-big2.png);
		width:16px;
		height:24px;
		right:0%;
		top:2px;
		background-repeat:no-repeat;
	}

	.overview-panel .text-image h3 a:hover:after {
		position:absolute;
		content:'';
		background-image:url(/content/dam/antares/images/icons/chevron-right-big2-red.png);
		width:16px;
		height:24px;
		right:0%;
		top:2px;
		background-repeat:no-repeat;
	}

	.overview-panel .media-body {
	   display: block;
	}
	.overview-panel p {
	   display: none;
	}
}
/*** end:: overview ***/

@media screen and (max-width: 767px) {
	.col-bottom-margin {
  margin-bottom: 15px;
	}

	.table {
	display: block;
	overflow-x: auto;
	}

	.team-photos table {
	width: 100%;
	}

	.team-photos td {
	display: block;
	}

	.nav-tabs>li {
	float: none;
	}

	.page-tabs .nav-tabs {
	border-bottom: none;
	}

	.page-tabs .nav-tabs>li {
	border-bottom: 1px solid #ddd;
	text-align: center;
	margin-bottom: 0px;
	}

	.page-tabs .nav-tabs>li>a {
	text-align: center;
	}

	.page-tabs .nav-tabs>li.active {
	border-bottom: 2px solid #e42313;
	}

	.page-tabs .nav-tabs>li.active>a {
	border-bottom: none;
	text-align: center;
	}

	.nav-footer-wrapper ul.nav-tabs li>a {
	text-align: left;
	}

  .nav-footer-wrapper ul.nav-tabs {
	margin-bottom: 0px;
	}

	.nav-footer-wrapper .nav>li>a {
	margin-bottom: 5px;
	}

	.nav-footer-wrapper .footer-copyright p {
	text-align: left !important;
	}
} /* end :: @media screen and (max-width: 767px) */

@media screen and (max-width:767px) {
.fund-details .table>thead>tr>th.no-border:first-child,
.fund-details .table>tbody>tr>th.no-border:first-child,
.fund-details .table>tfoot>tr>th.no-border:first-child,
.fund-details .table>thead>tr>td.no-border:first-child,
.fund-details .table>tbody>tr>td.no-border:first-child,
.fund-details .table>tfoot>tr>td.no-border:first-child {
border-bottom:1px solid #ddd;
}
  .team-photos td {
          width: 100% !important;
      }
}

@import "components/akamai-video.less";

.test-theme-less h5 {
  color:red;
}