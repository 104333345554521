.btn:focus {
  outline: dotted 2px #000
}

div.active:focus {
  outline: dotted 1px #000
}

a:focus {
  outline: dotted 1px #000
}

.close:hover,
.close:focus {
  outline: dotted 1px #000
}

.nav>li>a:hover, .nav>li>a:focus {
  outline: dotted 1px #000
}

.carousel-inner>.item {
  position: absolute;
  top: -999999em;
  display: block;
}

.carousel-inner>.active {
  top: 0
}

.carousel-inner>.active, .carousel-inner>.next, .carousel-inner>.prev {
  position: relative
}

.carousel-inner>.next, .carousel-inner>.prev {
  position: absolute;
  top: 0;
  width: 100%
}

/**
COMMENTING THIS OUT AS IT IS UP TO SITE THEME TO MANAGE
.alert-success {
    color: #2d4821;
}
.alert-info {
    color: #214c62;
}
.alert-warning {
    color: #6c4a00;
    background-color: #f9f1c6;
}
.alert-danger {
    color: #d2322d;
}
.alert-danger:hover {
    color: #c12f2a;
}
**/