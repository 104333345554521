body, html, a {outline:none !important; overflow-x:hidden; width:100%;}


.wrapper.wrapper-primary-nav {position:relative;}


/* header2 */
.navbar-default {background-color: #fff; border:0}
.navbar {border-radius:0; margin-bottom:0; padding-bottom:5px;}


/*logo */
.navbar-brand {position: absolute; z-index: 1; padding:0; margin-top:-30px; height: inherit; width: 150px;}


/*login button */
.login {float:right;}
.login .btn-default {background-color:#e42313; line-height:1; padding:15px 25px; border:0; border-radius:0; color:#fff; font-size:11px; font-weight:bold; letter-spacing:1px}


/* each primary top menu */
.nav>li>a {padding:10px}
.navbar-default .navbar-nav>li>a {color:#333}
.navbar-default .navbar-nav>li>a:hover {color:#a42313; }


/*Hamburger */

.navbar-toggle { margin:-31px 0 0 15px; border-radius:0; width:22px; height:17px}


@media screen and (max-width: 767px) {

/*logo */
.navbar-brand {position: initial; margin:5px 0 0 -10px; left: 20px;}
.navbar-brand img {height:64px !important;}

/*login */
.login {margin-right:-15px}

/*top header div */
#_content_antares_en_jcr_content_par-col-header_bootstrap_row_col1 {background-color:#58585B;}
.visible-xs {height:75px;}

/*Left panel canvas */

.navbar-offcanvas .navbar-header {background-color:#58585b; height:40px}
.navbar-offcanvas .navbar-toggle {width:85px; height:40px; margin-top:0; border:0; background-color:#fff;}
.navbar-offcanvas .navbar-nav {margin-top:0}
.navbar-offcanvas .nav>li>a {padding:15px; border-bottom:1px solid #d1d2d4;}
.navbar-offcanvas {background-color:#e6e7e8}
.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {border-color: #d1d2d4}

.dropdown-menu ul {display:none;}
.dropdown-menu .row.megamenu-row {margin-left:0; margin-right:0; }
.dropdown-menu .row.megamenu-row p {margin-bottom:0px}
.dropdown-menu .megamenu-col.col-sm-3 {padding:5px 0 5px 15px}

.row .mobile-menu .col-xs-11 {width: inherit;position: absolute; left: 5px;}

}


@media screen and (min-width: 768px) and (max-width: 991px) {

/*logo */
.navbar-brand {margin:-35px 0 0 -10px}
.navbar-brand img {height:74px !important}

/* primary top nav */
.navbar-nav {margin-top:45px; margin-left:-30px; width: 960px;}

}


@media screen and (min-width: 768px) and (max-width: 875px) {

/*logo */
.navbar-brand {margin:-35px 0 0 -10px}
.navbar-brand img {height:74px !important}

/* primary top nav */
.navbar-nav {margin-top:45px; margin-left:-30px; width: 748px;}

/* each primary top menu */
.navbar-default .navbar-nav>li>a {font-size:12px;}

.nav>li>a {padding:10px}

}



@media screen and (min-width: 992px) and (max-width: 1099px) {

/*logo */
.navbar-brand {margin:-35px 0 0 -10px}
.navbar-brand img {height:74px !important}

/* primary top nav */
.navbar-nav {margin-top:35px; margin-left:0px}

}


@media (max-width: 991px) {

.container {width:100%}

}


@media (min-width: 1100px){

/*main container */
.container {width: 1024px; padding:0}

/*logo */
.navbar-brand {margin-top:-30px}
.navbar-brand img {height:94px}

/* primary top nav */
.navbar-nav {margin-top:35px; margin-left: 145px;}

}

/*30-04-15*/


/*primary nav color */
.navbar-default .navbar-nav>li>a {color:#333}
.navbar-default .navbar-nav>li>a:hover {color:#e42313;}

/*hide caret & dropdown */
.megamenu-primary-nav li:first-child .caret,
.megamenu-primary-nav li:first-child .dropdown-menu,
.megamenu-primary-nav li:first-child + li + li + li .caret,
.megamenu-primary-nav li:first-child + li + li + li .downdown-menu,
.megamenu-primary-nav li:first-child + li + li + li +li .caret,
.megamenu-primary-nav li:first-child + li + li + li +li .downdown-menu {
    display: none;
}


/*dropdown links*/
.dropdown-menu a {color:#333;}
.dropdown-menu a:hover {color:#e42313; text-decoration:none;}


/*mobile*/
@media screen and (max-width: 767px) {

/*space top bottom between h5 menu left panel*/
.dropdown-menu .row.megamenu-row h5 {margin-bottom:0px}


/*dropdown menu shown*/
.navbar-offcanvas .dropdown-menu.shown {
  background-color:#f4f4f4
}

}




/*tablet-desktop*/

@media screen and (min-width: 768px) {

/*full width 100% dropdown on desktop */
.dropdown-menu {
    padding: 20px 1000em; /* trick from css-tricks comments */
    margin: 0 -1000em; background-color:#e6e7e8;
}


/*dropdown megamenu h5*/
.megamenu-col.col-sm-3 h5 { padding-bottom:10px;border-bottom: 1px solid #d3d4d5}

/*dropdown megamenu ul*/
.megamenu-col.col-sm-3 ul {padding-left: 15px; list-style: none}

/*dropdown megamenu li*/
.megamenu-col.col-sm-3 li {margin-top:-5px; padding:2px 0; font-size:12px;}


/*dropdown megamenu li triangle list type*/
.megamenu-col.col-sm-3 li:before {
    content: "";
    border-color: transparent #e42313;
    border-style: solid;
    border-width: 3px 0 3px 5px;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;

}


.megamenu-primary-nav li:nth-child(2) .dropdown-menu {}

/*dropdown megamenu bottom shadow*/
.dropdown-menu {border:0; box-shadow: 0 2px 3px rgba(0,0,0,.12);}

/*dropdown megamenu affecting primary nav*/
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover {color:#e42313}

}


/*desktop position dropdown container*/

@media screen and (min-width: 1100px) {
.megamenu-row { position:relative; margin-left:-250px;  width:984px}
}

/*tablet 992 to desktop position dropdown container*/

@media screen and (min-width: 992px) and (max-width: 1099px) {

.megamenu-row { position:relative; margin-left:-140px;  width:950px}


}


/*tablet 768 to 992 position dropdown container*/

@media screen and (min-width: 768px) and (max-width: 992px) {

.megamenu-row { position:relative; margin-left:-110px;  width:750px}


}

/* delete caret on Personal and institutional */
.megamenu-primary-nav li:nth-child(2) .caret, .megamenu-primary-nav li:nth-child(3) .caret {display:none;}


/* position all primary nav buttons */
.navbar-default .navbar-nav>li {position:relative}

/* put after on each of the primary nav buttons */
.navbar-default .navbar-nav>li:after
{position:absolute; content:'';}



@media screen and (max-width: 767px) {

/* put the chevron right in */
.navbar-default .navbar-nav>li:first-child:after, .navbar-default .navbar-nav>li:nth-child(4):after,
.navbar-default .navbar-nav>li:nth-child(5):after, .navbar-default .navbar-nav>li:nth-child(6):after
{width:8px; height:12px; top:23px; right:15px; background-image:url(/content/dam/antares/images/icons/chevron-right-big.png)}

/*chevron hover to red color */
.navbar-default .navbar-nav>li:first-child:hover:after, .navbar-default .navbar-nav>li:nth-child(4):hover:after,
.navbar-default .navbar-nav>li:nth-child(5):hover:after, .navbar-default .navbar-nav>li:nth-child(6):hover:after
{width:8px; height:12px; top:23px; right:15px; background-image:url(/content/dam/antares/images/icons/chevron-right-big-red.png)}


/* put the chevron down in */
.navbar-default .navbar-nav>li:nth-child(2):after, .navbar-default .navbar-nav>li:nth-child(3):after
{width:12px; height:8px; top:23px; right:15px; background-image:url(/content/dam/antares/images/icons/chevron-down-big.png)}

/*chevron hover to red color */
.navbar-default .navbar-nav>li:nth-child(2):hover:after, .navbar-default .navbar-nav>li:nth-child(3):hover:after
{background-image:url(/content/dam/antares/images/icons/chevron-down-red-big.png)}

/*dropdown megamenu affecting primary nav*/
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a {color:#e42313}


/*Position p on dropdown last col */
.megamenu-col.col-sm-3 p {font-size:14px;padding: 2px 0; margin-bottom: 0;}

/*Hide my account on mobile */
.megamenu-col.col-sm-3:nth-child(4) h5 a {display:none;}

/*Hide my account on mobile */
.megamenu-col.col-sm-3:nth-child(3) h5 {display:none;}

/*Adjust margin*/
.megamenu-col.col-sm-3:nth-child(3) {margin-top:-5px; margin-bottom:-10px}
.megamenu-col.col-sm-3:last-child {margin-top:-8px;}
}


@media screen and (min-width: 768px) {

/*put more padding right to fit the new chevron */
.navbar-default .navbar-nav>li:nth-child(2) a, .navbar-default .navbar-nav>li:nth-child(3) a {padding-right:22px;}

/* put the chevron down in */
.navbar-default .navbar-nav>li:nth-child(2):after, .navbar-default .navbar-nav>li:nth-child(3):after
{width:8px; height:7px; top:17px; right:8px; background-image:url(/content/dam/antares/images/icons/chevron-down.png)}

/* put the chevron down in */
.navbar-default .navbar-nav>li:nth-child(2):hover:after, .navbar-default .navbar-nav>li:nth-child(3):hover:after
{background-image:url(/content/dam/antares/images/icons/chevron-down-red.png)}

/* put the chevron right in */
.dropdown-menu>li>.megamenu-row .megamenu-col h5 a {position:relative; }
.dropdown-menu>li>.megamenu-row .megamenu-col h5 a:after { position:absolute; content:''; width:8px; height:7px; top:5px; right:5px; background-image:url(/content/dam/antares/images/icons/chevron-right.png)}
.dropdown-menu>li>.megamenu-row .megamenu-col h5 a:hover:after {background-image:url(/content/dam/antares/images/icons/chevron-right-red.png)}

/*Position dropdown container 3cols with width 1/3*/
.navbar-nav>li:nth-child(3)>.dropdown-menu>li>.megamenu-row .megamenu-col.col-sm-3 {width:33%;}

/*Position p on dropdown last col */
.megamenu-col.col-sm-3 p {font-size:13px;padding: 2px 0; margin-bottom: 0;}


}



@media screen and (min-width: 768px) and (max-width: 991px) {
/*Position dropdown container cols*/
.navbar-nav>li:nth-child(2)>.dropdown-menu>li>.megamenu-row {   left:10px;  width:740px}
.navbar-nav>li:nth-child(3)>.dropdown-menu>li>.megamenu-row {  left:-120px;  width:740px;}

}

@media screen and (min-width: 992px) and (max-width: 1099px) {
/*Position dropdown container cols*/
.navbar-nav>li:nth-child(2)>.dropdown-menu>li>.megamenu-row { left:25px;  width:900px}
.navbar-nav>li:nth-child(3)>.dropdown-menu>li>.megamenu-row { left:-120px;  width:900px;}

}


@media screen and (min-width: 1100px) {
/*Position dropdown container cols*/
.navbar-nav>li:nth-child(2)>.dropdown-menu>li>.megamenu-row { left:10px;  width:970px}
.navbar-nav>li:nth-child(3)>.dropdown-menu>li>.megamenu-row { left:-135px;  width:970px;}

}

/* Fixed megamenu full width with ios issue */
html, body {overflow-x:hidden;}

/* Login Button with icon */
.login .btn-default {position:relative; padding-left:40px; padding-right:15px}
.login .btn-default:before {position:absolute; content:''; left:15px; top:8px; width:15px; height:20px;
background-repeat:no-repeat; background-image:url(/content/dam/antares/images/icons/padlock-wht.png);
background-size:contain;}

/*hamburger icon */
.navbar-toggle {background: none !important; background-image: url(/content/dam/antares/images/icons/menu-mobile-sm.png) !important; background-repeat: no-repeat !important;}

/*close X left panel icon*/

.navbar-offcanvas .navbar-toggle {background-color:#fff !important;
background-image: url(/content/dam/antares/images/icons/close-x.png) !important; background-repeat: no-repeat !important;
}

@media screen and (max-width:767px){
/*show contact us in mobile left panel */
.megamenu-col.col-sm-3:nth-child(3) h5 {display:block; margin-top:5px;}

/*hide personal investor my account in mobile left panel */
.navbar-default .navbar-nav>li:nth-child(2) .megamenu-col.col-sm-3:nth-child(4) h5
 {display:none}

/*put more margin-top with col-4 personal investor left panel*/
.navbar-default .navbar-nav>li:nth-child(2) .megamenu-col.col-sm-3:nth-child(4)
 {margin-top:4px;}

}

@media screen and (min-width:768px){

/*make padding right =0 to have bigger space in col-4 to fit the icons & links */
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4)
{padding-right:0;}

/* make more padding left & position relative to put the icons on the left col */
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4) p:nth-child(2),
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4) p:nth-child(3),
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4) p:nth-child(4)
{position:relative; padding-left:18px}


/* put padlock icons in the login links in col-4 */
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4) p:nth-child(2):before,
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4) p:nth-child(3):before
{position:absolute; content:''; width:9px; height:12px;  background-image:url(/content/dam/antares/images/icons/padlock-red.png);
background-size:contain; left:0; top:4px;}

/* put phone icon in the contac us link in col-4 */
.navbar-default .navbar-nav>li:nth-child(2) #megamenu-col-4.megamenu-col.col-sm-3:nth-child(4) p:nth-child(4):before
{position:absolute; content:''; width:12px; height:12px;  background-image:url(/content/dam/antares/images/icons/phone-red.png);
background-size:contain; left:0; top:4px;}
}

/*top login button */
.lt-ie9 .login .btn-default { padding:15px 25px}
.lt-ie9 .login .btn-default:before { background:none}

@media screen and (min-width: 876px) and (max-width: 991px) {

/*put min width in the div so it wont scroll in mobile device */
.navbar-nav { width: 830px;}
}


@media screen and (max-width: 767px){

/*in mobile, make the same look from the desktop calendar: have more padding left & right and background color*/
.antares-unit-prices .dropdown-menu {
  padding: 20px 5px;
  width: 265px;
  background-color: #e6e7e8;
}

/* make it no border on the table like in the desktop */
.table-responsive {border:0;}

/* this below are not the new ones, just change the top from 23px to be 20px */

/* put the chevron right in */
.navbar-default .navbar-nav>li:first-child:after, .navbar-default .navbar-nav>li:nth-child(4):after,
.navbar-default .navbar-nav>li:nth-child(5):after, .navbar-default .navbar-nav>li:nth-child(6):after
{ top:20px;}

/*chevron hover to red color */
.navbar-default .navbar-nav>li:first-child:hover:after, .navbar-default .navbar-nav>li:nth-child(4):hover:after,
.navbar-default .navbar-nav>li:nth-child(5):hover:after, .navbar-default .navbar-nav>li:nth-child(6):hover:after
{ top:20px; }


}

@media screen and (min-width: 768px) and (max-width: 875px) {
.navbar-nav {width:730px;}
}

/*top login button */
.lt-ie9 .login .btn-default { padding:15px 25px;}
.lt-ie9 .login .btn-default:before { background:none;}

#megamenu-col-1 h5 a,
#megamenu-col-2 h5 a,
#megamenu-col-3 h5 a {
padding-right: 22px;
}
